import { useState, useEffect, useCallback } from 'react'
import { getMonth, forceTwoDigitTime } from './utilities/util'

// import setBoard from './utilities/setBoard'
import isMovesRemain from './utilities/isMovesRemain'
import GameTile from './GameTile'
import Modal from './Modal'
import ProgressBar from './ProgressBar'

import './TileSolitare.css'

import { db } from '../firebase/config'
import { doc, getDoc } from 'firebase/firestore'


export default function TileSolitare (){
    const date = new Date();
    const [month, day, year] = [
        date.getMonth(),
        date.getDate(),
        date.getFullYear(),
    ]

    const dateString = forceTwoDigitTime(month + 1) + 
                        forceTwoDigitTime(day) + forceTwoDigitTime(year)

    let tomorrow =  new Date()
    tomorrow.setDate(date.getDate() + 1)
    tomorrow.setHours(0,0,0,0)

    let dateDiff = (tomorrow.getTime() - date.getTime())/1000

    let hoursLeft = forceTwoDigitTime(Math.floor(dateDiff / 3600))
    let minutesLeft = forceTwoDigitTime(Math.floor(dateDiff/60) % 60)
    let secondsLeft = forceTwoDigitTime(Math.floor(dateDiff % 60))
    
    const [tiles, setTiles] = useState([])
    const [boardString, setBoardString] = useState('')
    const [ready, setReady] = useState(false)
    const [selectedTile, setSelectedTile] = useState(null)
    const [availableMoves, setAvailableMoves] = useState({})
    const [jumpedTile, setJumpedTile] = useState(null)
    const [selectedAvailable, setSelectedAvailable] = useState(null)
    const [tilesRemaining, setTilesRemaining] = useState(null)
    const [isGameOver, setIsGameOver] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [timeRemaining, setTimeRemaining] = useState([hoursLeft, minutesLeft, secondsLeft])
    const [newGame, setNewGame] = useState(null)
    const [boardDate, setBoardDate] = useState(dateString)

    useEffect ( () => {
        setNewGame(1)
    }, [])
    

    useEffect ( () => {
        const getBoardString = async () => {
            console.log(boardDate)
            const docRef = doc(db, "solitareBoards", boardDate);
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                setBoardString( docSnap.data().gameBoard )
                console.log(docSnap.data())
            } else {
                console.log("No Board Found");
            }
        }
        getBoardString()
        // console.log(boardString)
    }, [newGame])

    
    useEffect ( () => {
        setBoardString('')
        const createGameBoard = () => {
            if (boardString.length > 1) {
                const gameBoard = boardString.split('')
                const columns = 'ABCDEFGHI'
                let tileCount = 0
                let currentPosition = 0
                for (let r = 0; r < 9; r++){
                    for (let c = 0; c < 9; c++){
                        if (gameBoard[currentPosition] !== -1) {
                            const currentType = parseInt(gameBoard[currentPosition])
                            const currentTile = {'id':columns[c] + r.toString(),
                                    'type':currentType,
                                    'selected': 0,
                                    'available': 0,
                                    'source': 0,
                                    'jumped':0,
                                    'destination':0}
                            gameBoard[currentPosition] = currentTile
                            if (currentType > 1){tileCount++}
                        } else{
                            const currentTile = {'id':columns[c] + r.toString(),
                                    'type': 0,
                                    'selected': 0,
                                    'available': 0,
                                    'source': 0,
                                    'jumped':0,
                                    'destination':0}
                            gameBoard[currentPosition] = currentTile
                        }
                        currentPosition++
                    }
                }
                console.log(gameBoard)
                setTiles(gameBoard)
                setTilesRemaining(tileCount)
                setReady(true)
            }
        }
        createGameBoard()
        
    }, [boardString])
    

    const handleTileClick = (tile) => {
        if (tile.available === 1 ){
            setSelectedAvailable(tile.id)
            setJumpedTile(availableMoves[tile.id]) //////////
        }else if (tile.type > 1){
            setSelectedTile(tile.id)
        }
    }


    // this is called in a useEffect when selectedTile changes
    const show_available_moves = useCallback( () => {
        //get the currently selected tile
        const columns = 'ABCDEFGHI'
        const col_num = columns.indexOf(selectedTile[0])
        const row_num = parseInt(selectedTile[1])
        // console.log(row_num)
        // move order -> up, right, down, left
        const available_moves = {}
        if (row_num > 1){
            // if position above is occupied by a tile
            const adjacentId = selectedTile[0] + (row_num - 1).toString()
            if (tiles.find(tile => tile.id === adjacentId).type > 1) {
                available_moves[ selectedTile[0]+(row_num - 2).toString() ] = adjacentId
            }
        }
        if (col_num < 7){
             // if position right is occupied by a tile
             const adjacentId = columns[col_num + 1] + row_num.toString()
             if (tiles.find(tile => tile.id === adjacentId).type > 1) {
                available_moves[columns[col_num + 2]+row_num.toString()] = adjacentId
             }
        }
        if (row_num < 7){
            const adjacentId = selectedTile[0] + (row_num + 1).toString()
             if (tiles.find(tile => tile.id === adjacentId).type > 1) {
                available_moves[selectedTile[0]+(row_num + 2).toString()] = adjacentId
             }
        }
        if (col_num > 1){
            const adjacentId = columns[col_num - 1] + row_num.toString()
             if (tiles.find(tile => tile.id === adjacentId).type > 1) {
                available_moves[columns[col_num - 2] + row_num.toString()] = adjacentId
             }            
        }

        setAvailableMoves(available_moves)
    } , [selectedTile, ])


    const startNewGame = () => {
        setIsGameOver(false)
        setShowModal(false)
        const dateCheck = new Date();
        const [checkMonth, checkDay, checkYear] = [
            dateCheck.getMonth(),
            dateCheck.getDate(),
            dateCheck.getFullYear(),
        ]
        const checkDateString = forceTwoDigitTime(checkMonth + 1) + 
        forceTwoDigitTime(checkDay) + forceTwoDigitTime(checkYear)
        setBoardDate(checkDateString)
        setNewGame( newGame + 1)
    }


    /**
     * When the state of selectedTile is updated to a new tile:
     * re-map the tiles to change the tile that is identified by selectedTile to have
     * a selected attribute of 1.  Then use the setTiles hook to update game state.
     * Finally, run the show_available move function to update valid game moves.
     */
    useEffect( () => {
            setTiles(prevTiles => {
                return prevTiles.map(tile => {
                if(tile.id === selectedTile){
                    //roughly equivalent to making a new copy, then changing an attribute
                    return {...tile, selected: 1}
                }else{
                    return {...tile, selected: 0}
                }
            })})
            if (selectedTile !== null){
                show_available_moves()
            }
                
        },[selectedTile, show_available_moves]
    )


    /**
     * When the state of available moves has been updated:
     * re-map the game tiles to set the available attribute to 1 for tiles that appear
     * in the available_moves list.
     */
    useEffect ( () => {
        setTiles(prevTiles => {
            return prevTiles.map(tile => {

            if(availableMoves[tile.id] !== undefined && tile.type === 1){
                //roughly equivalent to making a new copy, then changing an attribute
                return {...tile, available: 1}
            }else{
                return {...tile, available: 0}
            }
        })})
    }, [availableMoves]

    )

    /**
     * When the state of selectedTile is updated to a new tile:
     * re-map the tiles to change the tile that is identified by selectedTile to have
     * a selected attribute of 1.  Then use the setTiles hook to update game state.
     * Finally, run the show_available move function to update valid game moves.
     */
    useEffect( () => {
        setTiles(prevTiles => {
            return prevTiles.map(tile => {
                // console.log('What is in selectedAvailable: ' + selectedAvailable)
                if(tile.id === selectedTile){
                        //roughly equivalent to making a new copy, then changing an attribute
                        return {...tile, type: 1, selected: 0}
                }else if(tile.id === selectedAvailable){
                    //roughly equivalent to making a new copy, then changing an attribute
                    // console.log('Should change to: ' + tiles.find(tile => tile.id === selectedTile).type)
                    return {...tile, type: tiles.find(tile => tile.id === selectedTile).type, available:0, selected:0}
                } else if (tile.id === jumpedTile){
                    return {...tile, type: 1, available:0, selected:0}
                }else{
                    return {...tile, selected: 0, available: 0}
                }
        })})
    },[selectedAvailable]
    )


    useEffect( ()=> {
        let tileCount = 0
        for (let i = 0; i < tiles.length; i++){
            if (tiles[i].type > 1){
                tileCount ++
            }
        }
        setTilesRemaining(tileCount)
    }, [tiles])



    const printTileID = (tile) => {
        console.log(tile.id)
    }


    useEffect(
        () => {
            if (tiles.length > 1 && !isMovesRemain(tiles) && ready){
                setIsGameOver(true)
                setReady(false)
            }
        }, [tilesRemaining, ready]
    )

    useEffect(
        () => {
            if (isGameOver === true) {
                setTimeout(() => setShowModal(true),500) 
            }
        }, [isGameOver]
    )
    
    useEffect(
        () => {
            let newTomorrow =  new Date()
            newTomorrow.setDate(date.getDate() + 1)
            newTomorrow.setHours(0,0,0,0)

            let newDateDiff = (newTomorrow.getTime() - date.getTime())/1000

            let newHoursLeft = forceTwoDigitTime(Math.floor(newDateDiff / 3600))
            let newMinutesLeft = forceTwoDigitTime(Math.floor(newDateDiff/60) % 60)
            let newSecondsLeft = forceTwoDigitTime(Math.floor(newDateDiff % 60))
            
            // this pattern will stop 
            const intervalId = setInterval( () => {
                setTimeRemaining([newHoursLeft,newMinutesLeft,newSecondsLeft])
            }, 100)

            // console.log([newHoursLeft,newMinutesLeft,newSecondsLeft])            
            
            return () => clearInterval(intervalId)
            

        },[timeRemaining]
    )


    return(
        <div className='solitare-game-wrapper'>
            <div className='gameTitle'>
                Tile Solitare
            </div>
            <div className='timeBanner'>
                <div className='gameDate'>
                    {getMonth(month)} {day}, {year}
                </div>
                <div className='timeRemaining'>
                    <div className='nextBoardIn'>Next Board in</div>
                    <div className='timeTicker'>{timeRemaining[0]}:{timeRemaining[1]}:{timeRemaining[2]}</div>
                </div>
            </div>
            
            <div className='solitare-tile-grid'>
                {tiles.map( (tile) => {
                    return(
                        <GameTile key={tile.id} 
                        className='GameTile' 
                        tile={tile} 
                        scheme='dark'
                        printId={printTileID}
                        handleTileClick={handleTileClick}/>
                    )
                })}
            </div>
            <div className='bottomStatBanner'>
                <ProgressBar 
                    maxScore = {54} // maybe this should be like top 75%
                    currentScore = {tilesRemaining}
                    bestScore = {5}
                    topFifty = {13}
                    topTwentyFive = {6}
                    topTen = {3}
                />
                {/* <div className='tilesRemaining'>
                    Tiles Remaining: {tilesRemaining}
                </div> */}
            </div>
            
            {showModal && <Modal newGameClick={startNewGame} remainingTiles={tilesRemaining}/> }
        </div>
        
    )
}

