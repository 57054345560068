import './About.css';

export default function About (){

    


    return(
        <div className="aboutContainer">
            <div className="aboutTitle">
                About Rocketman Games
            </div>
            <div className="aboutBody">
            I'm a high school computer science teacher in upstate New York. 
            I originally started building rocketman.games with the goal of gaining a 
            deeper understanding of web development. In my spare time I have continued 
            to add functionality to the site, and decided to deploy my work to the web.
            <br/><br/>
            Planned future functionality includes: daily challenge boards, user statistics, 
            and additional games. I would love to hear your suggestions, feel free to contact 
            me at dtaylor@rocketman.games.


            </div>
        </div>
        
    )
}
