import './ProgressBar.css'

export default function ProgressBar ({maxScore, currentScore, bestScore, topFifty, topTwentyFive, topTen}){



    let topTenRight = Math.ceil((topTen / maxScore) * 100) // likely need to move this logic into TileSolitare and manage its state
    let topTwentyFiveRight = Math.ceil(((topTwentyFive)/ maxScore) * 100) - topTenRight
    let topFiftyWidth = Math.ceil(((topFifty)/ maxScore) * 100) - topTwentyFiveRight

    const topFiftyLeft = topTenRight + topTwentyFiveRight + '%'

    topTenRight += '%'
    topTwentyFiveRight += '%'
    topFiftyWidth += '%'

    const bestScorePositionLeft = Math.ceil((bestScore / maxScore) * 100) + '%'
    const currentScorePositionLeft = Math.ceil((currentScore / maxScore) * 100) + '%'

    return(
        <div className='progressBarContainer'>
            <div className='progressBarPercentiles'>
                <div className='progressBarTop10' 
                    style={{width:topTenRight}}>
                </div>
                <div className='progressBarTop25'
                    style={{width:topTwentyFiveRight, left:topTenRight}}>
                </div>
                <div className='progressBarTop50'
                    style={{width:topFiftyWidth, left:topFiftyLeft}}>
                </div>
                <div className='progressBarUserBestScorePlot'></div>
                <div className='progressBarUserCurrentScore'>{currentScore}</div>
            </div>
        </div>
    )
}