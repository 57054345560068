import style from './Modal.module.css'

export default function Modal({newGameClick,remainingTiles}) {
  return (

      <div className={style.modal}>
          <h2 className={style.title}>Game Over</h2>
          <p className={style.bodyText}>You had {remainingTiles} tiles remaining!</p>
          <div className='buttonContainer'>
            <button className={style.button}>Share</button>
            <button className={style.button} onClick={newGameClick}>Start New Game</button>
            <button className={style.button}>Play Old Board</button>
          </div>
          {/* <div className={style.modalAdContainer}>
            <div className={style.modalInnerAdContainer}>
              Future Ad Location
            </div>
          </div> */}
          

      </div>

  )
}
