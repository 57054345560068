import './GameTile.css'

export default function GameTile ({tile, scheme, printId, handleTileClick}){



    const colors = {
        'test':['white','red','blue','green','yellow','purple','#1b1523'],
        'dark':['#FFFADD','#973A5E','#392581','#19A09B','#C2690D','#572576','#1b1523','yellow']
    }
    
    const colorScheme = colors[scheme]
    const inPlayClass = 'tile inPlay'
    const inPlaySelectedClass = 'tile inPlay selected'
    const emptyClass = 'tile empty'
    const emptyAvailableClass = ' tile empty available'


    switch (tile.type){
        case 1: // type 1 represents empty space
            return (
                <div key={tile.id} 
                    className={tile.available === 1? emptyAvailableClass : emptyClass}
                    // we need to refactor this so that styles live in the css, with exception of background color
                    style={tile.available === 1 ?
                        {backgroundColor:colorScheme[0],
                        boxShadow: 'inset 4px 4px 8px 3px rgba(0,0,0,0.9), 0 0 1rem #FF9D00ff' }
                        :{backgroundColor:colorScheme[0]}}
                    tile={tile}
                    onClick={() => handleTileClick(tile)}>
                </div>
            )
        case 2: //type 2-6 represent playable tiles
            return (
                <div key={tile.id} 
                    className={tile.selected === 1? inPlaySelectedClass : inPlayClass}
                    style={tile.selected === 1 ? 
                        {backgroundColor:colorScheme[1],
                        } 
                        :{backgroundColor:colorScheme[1]} }
                    tile={tile}
                    onClick={() => handleTileClick(tile)}>
                </div>
            )
        case 3:
            return (
                <div key={tile.id} 
                    className={tile.selected === 1? inPlaySelectedClass : inPlayClass}
                    style={tile.selected === 1 ? 
                        {backgroundColor:colorScheme[2],
                        boxShadow: 'inset 4px 4px 8px 3px rgba(0,0,0,0.5), 0 0 1rem rgba(164, 234, 34, .9)' } 
                        :{backgroundColor:colorScheme[2]} }
                    tile={tile}
                    onClick={() => handleTileClick(tile)}>
                </div>
            )
        case 4:
            return (
                <div key={tile.id} 
                    className={tile.selected === 1? inPlaySelectedClass : inPlayClass}
                    style={tile.selected === 1 ? 
                        {backgroundColor:colorScheme[3],
                        boxShadow: 'inset 4px 4px 8px 3px rgba(0,0,0,0.7), 0 0 1rem rgba(164, 234, 34, .9)' } 
                        :{backgroundColor:colorScheme[3]} }
                    tile={tile}
                    onClick={() => handleTileClick(tile)}>
                </div>
            )
        case 5:
            return (
                <div key={tile.id} 
                    className={tile.selected === 1? inPlaySelectedClass : inPlayClass}
                    style={tile.selected === 1 ? 
                        {backgroundColor:colorScheme[4],
                        boxShadow: 'inset 4px 4px 8px 3px rgba(0,0,0,0.7), 0 0 1rem rgba(164, 234, 34, .9)' } 
                        :{backgroundColor:colorScheme[4]} }
                    tile={tile}
                    onClick={() => handleTileClick(tile)}>
                </div>
            )
        case 6:
            return (
                <div key={tile.id} 
                    className={tile.selected === 1? inPlaySelectedClass : inPlayClass}
                    style={tile.selected === 1 ? 
                        {backgroundColor:colorScheme[5],
                        boxShadow: 'inset 4px 4px 8px 3px rgba(0,0,0,0.5), 0 0 1rem rgba(164, 234, 34, .9)' } 
                        :{backgroundColor:colorScheme[5]} }
                    tile={tile}
                    onClick={() => handleTileClick(tile)}>
                </div>
            )
        default: // the default case represents out of bounds, typically type 0
            return (
                <div key={tile.id} 
                    className='tile outOfBounds'
                    style={{backgroundColor:colorScheme[6]}}
                    tile={tile}>
                </div>
            )
    }
}
