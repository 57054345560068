import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDfmazuJqdcc3FHN8yjQF_-nW684bWbMyU",
  authDomain: "rocket-man-games.firebaseapp.com",
  projectId: "rocket-man-games",
  storageBucket: "rocket-man-games.appspot.com",
  messagingSenderId: "407691694651",
  appId: "1:407691694651:web:39f513070ea426b5d6421e",
  measurementId: "G-LWLYJM1VQJ"
};

// Initialize Firebase
initializeApp(firebaseConfig);
const db = getFirestore()

export { db }