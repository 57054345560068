const hasValidMoves = (tiles, tileId) => {
    const columns = 'ABCDEFGHI'
    const col_num = columns.indexOf(tileId[0])
    const row_num = parseInt(tileId[1])

    if (tiles.find(tile => tile.id === tileId).type < 2){
        return false
    }
    
    // move order -> up, right, down, left
    if (row_num > 1){
        // if position above is occupied by a tile
        const adjacentId = tileId[0] + (row_num - 1).toString()
        const jumpToId = tileId[0] + (row_num - 2).toString()
        if (tiles.find(tile => tile.id === adjacentId).type > 1) {
            if (tiles.find(tile => tile.id === jumpToId).type === 1) {
                return true
            }
        }
    }
    if (col_num < 7){
        // if position right is occupied by a tile
        const adjacentId = columns[col_num + 1] + row_num.toString()
        const jumpToId = columns[col_num + 2] + row_num.toString()
        if (tiles.find(tile => tile.id === adjacentId).type > 1) {
            if (tiles.find(tile => tile.id === jumpToId).type === 1) {
                return true
            }
        }
    }
    if (row_num < 7){
        const adjacentId = tileId[0] + (row_num + 1).toString()
        const jumpToId = tileId[0] + (row_num + 2).toString()
        if (tiles.find(tile => tile.id === adjacentId).type > 1) {
            if (tiles.find(tile => tile.id === jumpToId).type === 1) {
                return true
            }
        }
    }
    if (col_num > 1){
        const adjacentId = columns[col_num - 1] + row_num.toString()
        const jumpToId = columns[col_num - 2] + row_num.toString()
        if (tiles.find(tile => tile.id === adjacentId).type > 1) {
            if (tiles.find(tile => tile.id === jumpToId).type === 1) {
                return true
            }
        }            
    }
}


export default function isMovesRemain(boardState) {

    for (let i = 0; i < boardState.length; i++){
        const currentTile = boardState[i]
        if (currentTile.type > 1){
            if (hasValidMoves(boardState, currentTile.id)){
                return true
            }
            
        }
    }
    return false;
}