// import { useState } from 'react'
import {BrowserRouter, Link, Route, Routes, Navigate} from 'react-router-dom'
import { useWindowSize } from './components/utilities/util'
import TileSolitare from './components/TileSolitare';
import About from './components/About';
import Statistics from './components/Statistics';
import smallRocketMan from './media/smallAstro.png'
import rocketManSideBar from './media/rocket_man_games_lg.png'
import './App.css';


function App() {

  const size = useWindowSize();

  return (
    <div className="App">
      <div className='pageContent'>
        <BrowserRouter>
        
          {size.width >= 950 && 
            <div className='left'>
              <div className='menuBarContainer'>
                <img className="menu_bar_img" src={rocketManSideBar} alt='menuBar'></img>
                <div className='menuBar'>
                {/* <button className='menuButton'>Login</button> */}
                  <Link to='/'><button className='menuButton'>Tile Solitare</button></Link>
                  <Link to='/Privacy_Policy'><button className='menuButton'>Privacy Policy</button></Link>
                  {/* <Link to='/Tile Sweeper'><button className='menuButton'>Tile Sweeper</button></Link>
                  <Link to='/Tile Crush'><button className='menuButton'>Tile Crush</button></Link> */}
                  {/* <Link to='/Statistics'><button className='menuButton'>Statistics</button></Link> */}
                  <Link to='/About'><button className='menuButton'>About</button></Link>
                </div>
              </div>
            </div>}

          <div className='middle'>
            <div className='topBanner'>
                  {size.width > 425 &&
                  <img className="logo_icon" src={smallRocketMan} alt='logoIcon'/>}
                  <h1 className="siteTitle">
                      Rocketman Games
                  </h1>
            </div>
            
            <div className='gameArea'>
              <Routes>
                    {/* We can use similar logic as the line below to change the redirect if
                    a user is logged in... */}
                    <Route path='/' element={ <Navigate to='/tile_solitare' /> }></Route> 
                    <Route path='/tile_solitare' element={ < TileSolitare /> }></Route>
                    <Route path='/about' element={ < About /> }></Route>
                    <Route path='/statistics' element={ < Statistics /> }></Route>
              </Routes>
            </div>
            <div className='bottomBanner'>
              <div>
                {/* {size.width}px / {size.height}px
                {size.width >= 1100 && <div>Full-Size</div>}
                {size.width < 1100 && size.width > 800 && <div>Mid-Size</div>}
                {size.width <= 800 && <div>Mobile-Size</div>} */}

              </div>
            </div>
            
          </div>
        </BrowserRouter>
        {/* {size.width >= 800 &&
          <div className='right'>
            <div className='futureAdLoc'>Future Ad Location</div>
          </div>} */}
      </div>
    </div>
  );
}

export default App;
