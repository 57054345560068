export default function Statistics (){

    return(
        <div className="statisticsContainer">
            <div className="statisticsTitle">
                'userNames' Statistics
            </div>
            <div className="aboutBody">
                Place holder for statistics body
            </div>
        </div>
        
    )
}